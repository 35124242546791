import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { editUser, getWatchlistById, getSymbols, editWatchList } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';
import { labelOptions, setupOptions, watchListCategoryOptions,watchListSetupOptions } from '../../globals/constant';
import { getStockLTP, uploadImageS3 } from '../../common/Common'




export default function EditWatchList() {

    let { id } = useParams();
    const [watchlistDetails, setWatchlistDetails] = useState(null)
    const [chartImage, setChartImage] = useState(null);
    const [holdingDays, setHoldingDays] = useState(null);
    const [profit, setProfit] = useState({ price: '', percentage: '' });
    const [tradeDirection, setTradeDirection] = useState(0);
    const [tradeCategory, setTradeCategory] = useState(null)
    const [ltp, setLTP] = useState(null)
    const [tradeDuration, setTradeDuration] = useState(null)
    const [setup, setSetUp] = useState(null)

    useEffect(() => {

        if (id != undefined) {
            getWatchlistById({ "watchlist_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setWatchlistDetails(res.data)
                    setChartImage(res.data.chart_image);
                    setHoldingDays(res.data.holding_days);
                    setTradeDirection(res.data.trade_direction)
                    setProfit(res.data.profit)
                    setLTP(res.data.ltp)
                    const foundObject = watchListCategoryOptions.find((type) => type.label === res.data.trade_category);
                    setTradeCategory(foundObject)
                    setTradeDuration(res.data.trade_duration)
                    setSetUp(watchListSetupOptions.find((type) => type.label === res.data.setup))
                }
            });
        }

    }, [id])

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Please enter name").max(128, "Name must be at most 128 character"),
        // title: Yup.string().required('Please enter title').max(128, 'Title must be at most 128 characters'),
        // sub_text: Yup.string().required('Please enter sub text').max(128, 'Sub text must be at most 128 characters'), 
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate('/watchlist');
    }

    const image = chartImage != null ? `${process.env.REACT_APP_S3URL}/chart_image/${chartImage}` : '';

    const onSubmit = (data) => {
        editWatchList({
            profit: profit,
            holding_days: holdingDays,
            chart_image: chartImage,
            trade_category: tradeCategory,
            watchlist_id: id,
            setup : setup,
        }).then((response) => {
            response = JSON.parse(response);
            if (response.code === 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    navigate('/watchlist');
                }, 2000);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    };
    const calculatePercentage = (price, ltp) => {
        if (price && ltp) {
            return ((price - ltp) / ltp) * 100;
        }
        return null;
    };
    const handleProfitChange = (event, type) => {
        if (type === 'price') {
            const percentage = calculatePercentage(event.target.value, ltp).toFixed(2);
            setProfit({ percentage: percentage, price: event.target.value })
        } else {
            const price = (ltp * (1 + event.target.value) / 100).toFixed(2);
            setProfit({ percentage: event.target.value, price: price })
        }
    };
   
    const handleFileInput = async (e) => {
        const imageName = await uploadImageS3({ "dir_name": 'chart_image', 'file': e.target.files[0] })
        setChartImage(imageName)
    }
    if (watchlistDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Edit WatchList</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Trade Direction </label>
                                            <div>
                                                <input type="radio" id="age1" name="stype" value="1" checked={tradeDirection === 1} readOnly />&nbsp;LONG
                                                &nbsp;
                                                <input type="radio" id="age2" name="stype" value="2" checked={tradeDirection === 2} readOnly />&nbsp;SHORT
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className="font-weight-bold"> Publish Date </label>
                                            <div>
                                                <input type="text" readOnly value={watchlistDetails.publish_date} className="form-control" {...register(`publish_date`)} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label> Stock Name </label>
                                            <div>
                                                <input type="text" readOnly value={watchlistDetails.symbol_id.scrip_name} className="form-control" {...register(`scrip_name`)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label> LTP </label>
                                            <div>
                                                <input type="text" readOnly value={watchlistDetails.ltp} className="form-control" {...register(`ltp`)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className="font-weight-bold"> Potential Gain </label>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label htmlFor="percentage">Percentage:</label>
                                                    <div>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            placeholder="Please enter Numbers"
                                                            onChange={(event) => handleProfitChange(event, 'percentage')}
                                                            value={profit.percentage}
                                                            required
                                                            step={0.01}
                                                            onInvalid={(e) => {
                                                                e.target.setCustomValidity('Please enter profit');
                                                            }}
                                                            onInput={(e) => e.target.setCustomValidity('')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <span style={{ marginLeft: '-23px', marginRight: '6px' }}>OR</span>
                                                    <label htmlFor="price">Price:</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        placeholder="Please enter Price"
                                                        step={0.01}
                                                        onChange={(event) => handleProfitChange(event, 'price')}
                                                        value={profit.price}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Select Trade Category </label>
                                            <div>
                                                <Controller
                                                    name="label"
                                                    control={control}
                                                    rules={{ required: 'Please select trade category' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            options={watchListCategoryOptions}
                                                            value={tradeCategory}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                setTradeCategory(selectedOption);
                                                                setTradeDuration(selectedOption.days);
                                                            }}
                                                            placeholder="Select Trade Category"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Select Setup </label>
                                            <div>
                                                <Controller
                                                    name="setup"
                                                    control={control}
                                                    rules={{ required: 'Please select setup' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            options={watchListSetupOptions}
                                                            value={setup}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                setSetUp(selectedOption);
                                                            }}
                                                            placeholder="Select Setup"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />
                                                {/* <div className="invalid-feedback">{errors.label?.message}</div> */}
                                            </div>

                                        </div>
                                        
                                        <div className='col-md-3'>
                                            <label> Trade Duration</label>
                                            <input type="text" className="form-control" min={0} value={tradeDuration} readOnly />
                                        </div>
                                        <div className='col-md-3'>
                                            <label> Holding Period [Days] </label>
                                            <input type="number" className="form-control" min={0} readOnly />
                                        </div>

                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <label> Upload Chart Image </label>
                                            <div>
                                                <input type="file" {...register('chart_image')} className="form-control" onChange={(event) => handleFileInput(event)} />

                                            </div>
                                            {image != '' && (<span class="loc-caption">
                                                <div className="mb-3">
                                                    <div className="profile-pic">

                                                        <img src={image} id="output" width="200" />
                                                    </div>
                                                </div>
                                            </span>)}
                                        </div>


                                    </div>
                                </div>

                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}